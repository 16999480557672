import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import {
  LargeHeader,
  MediumHeader,
  SmallHeader,
  Subheader,
  Paragraph,
  TextSection,
  RectangularImageWrapper,
  CircularImageWrapper,
  SeperatorBar,
  Spacer,
} from "../components/BasicText/BasicTextElements"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout activePage="about">
    <TextSection>
      <MediumHeader>About Me</MediumHeader>
      <StaticImage
        src="../images/ZachPortrait.jpg"
        alt="Portrait of Zach Levy"
        width={1000}
        height={700}
      />
      <Paragraph>Welcome to my website!</Paragraph>
      <Paragraph></Paragraph>
      <Paragraph>
        I made this site to share some of my technical projects and my gallery
        of the cakes that I have created. I plan on updating this website
        periodically and adding my latest adventures in baking, programming, and
        electronics or anything else that I think is worth sharing.
      </Paragraph>
      <Paragraph>
        I'm currently a student at UW-Madison studying electrical engineering.
        Some of my main areas of interest include acoustics, semiconductors, web
        development, astronomy, and physics. In my spare time I also enjoy
        gardening, hiking, and baking.
      </Paragraph>
      <Paragraph>
        You can contact me at{" "}
        <a href="mailto:zalevy357@gmail.com">zach@zalevy.com</a>. Feel free to
        reach out with any questions, comments, or suggestions.
      </Paragraph>
      <SeperatorBar />
      <Spacer height="60px" />
    </TextSection>
  </Layout>
)

export const Head = () => (
  <SEO
    title="About"
    description="Zach Levy's biography, contact information, and details about the website"
  />
)

export default AboutPage
