import styled from "styled-components"
import { device } from "../../../devices"

export const TextSection = styled.div`
  max-width: 600px;
  margin: auto;
  padding: 0px 20px;
`

export const LargeHeader = styled.h1`
  font-size: 3.5rem;
  font-weight: lighter;
  text-align: center;
  margin: 20px;

  @media ${device.mobile} {
    font-size: 3rem;
  }
`

export const MediumHeader = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  font-weight: lighter;
  text-align: center;
  margin: 20px;
`

export const SmallHeader = styled.h3`
  text-align: center;
`

export const Subheader = styled.h4`
  text-align: center;
`

export const Paragraph = styled.p`
  text-align: ${props => (props.textalign ? props.textalign : "left")};
  font-size: 1.1rem;
  margin: 16px 0px;
`

export const RectangularImageWrapper = styled.div``

export const CircularImageWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
`

export const SeperatorBar = styled.div`
  width: 140px;
  height: 12px;
  background: rgb(115, 32, 135);
  margin: 16px auto;
  border-radius: 10px;
`

export const Spacer = styled.div`
  height: ${props => (props.height ? props.height : "16px")};
`
